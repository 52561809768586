import store from '@/store'
import Home from '@/views/Home'
import PageNotFound from '@/views/PageNotFound'
import { Auth } from 'aws-amplify'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/logout',
    beforeEnter: async (to, from, next) => {
      await store.dispatch('resetState')
      next({
        path: '/'
      })
    }
  },
  {
    path: '/tenants',
    name: 'Tenant',
    component: () => import('@/components/tenant-groups/TenantGroupRepository.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/users',
    name: 'User',
    component: () => import('@/components/users/UserRepository.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/users/new',
    name: 'NewUser',
    component: () => import('@/components/users/UserFormNew.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/users/:username/edit',
    name: 'EditUser',
    component: () => import('@/components/users/UserFormEdit.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/sites',
    name: 'Site',
    component: () => import('@/components/site-groups/SiteGroupTree.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/sites/new',
    name: 'NewSite',
    component: () => import('@/components/sites/SiteCardNew.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/sites/:id/edit',
    name: 'EditSite',
    component: () => import('@/components/sites/SiteCardEdit.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/MiSiteWeb',
    name: 'MiSiteWeb',
    component: () => import('@/views/MiSiteWeb.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound,
    beforeEnter: requireAuth
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

async function requireAuth (to, from, next) {
  try {
    console.log('[requireAuth]')
    const user = await Auth.currentAuthenticatedUser()
    if (!user) {
      next({
        path: from.fullPath
      })
    }

    await Auth.currentSession()
    next()
  } catch (err) {
    console.log('[requireAuth] error:', err)
    next({
      path: '/logout'
    })
  }
}

export default router
