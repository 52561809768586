<template>
  <v-select
    v-model="locale"
    :items="locales"
    item-text="text"
    return-object
    :menu-props="{ bottom: true, offsetY: true, closeOnClick: true }"
    @change="switchLocale(locale)"
  >
    <template v-slot:item="data">
      <flag
        :iso="data.item.icon"
        class="mr-2"
      />
      <v-list-item-content class="pa-0">
        {{ data.item.text }}
      </v-list-item-content>
    </template>
    <template v-slot:selection="data">
      <flag
        :iso="data.item.icon"
        class="mr-2"
      />
      <v-list-item-content class="pa-0">
        {{ data.item.text }}
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LocaleSelect',
  data () {
    return {
      locale: {}
    }
  },
  computed: {
    ...mapGetters(['locales', 'currentLocale'])
  },
  created () {
    this.locale = this.currentLocale
  },
  methods: {
    switchLocale (locale) {
      locale = locale.value
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale
      }
      const rtlLanguages = ['he']
      const rtl = !!rtlLanguages.find(lang => locale === lang)
      this.$vuetify.lang.current = locale
      this.$vuetify.rtl = rtl
      this.$i18n.rtl = rtl
      this.$store.commit('setRtl', rtl)
      this.$emit('change')
    }
  }
}
</script>
