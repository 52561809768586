export const getDefaultState = () => ({
  currentTenant: null,
  currentTenantId: '',
  userPrimaryTenant: '',
  tenantsList: [],
  tenantGroupsList: [],
  tenantGroupsRoles: [
    'TenantGroupAdmin'
  ]
})

export const state = getDefaultState()
