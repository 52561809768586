export function createUniqueId () {
  return Math.random().toString(36).substr(2, 9)
}

/**
 * @method arrayToObject
 * @description Converts an array of name and values to an object with properties
 * @param {{name: string, value: string}[]} arr
 */
export function arrayToObject (arr) {
  var obj = {}
  for (const el of arr) {
    obj[el.name] = el.value
  }
  return obj
}
