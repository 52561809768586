import { actions } from '@/store/modules/entities/entities-actions'
import { getters } from '@/store/modules/entities/entities-getters'
import { mutations } from '@/store/modules/entities/entities-mutations'
import { state } from '@/store/modules/entities/entities-state'
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export const moduleEntities = {
  state,
  mutations,
  getters,
  actions
}
