import { Auth } from 'aws-amplify'

export const actions = {
  resetState: async ({ commit }) => {
    commit('setLoggedIn', false)
    commit('setCognitoInfo', null)
    commit('setUserName', null)
    commit('setCurrentTenantId', null)
    commit('setTenantsList', [])
    commit('setCurrentLocale', 'en')
    commit('resetUsers')
    commit('resetEntities')
    commit('resetTenants')
    await Auth.signOut()
  }
}
