<template>
  <v-expansion-panels flat>
    <v-expansion-panel>
      <v-expansion-panel-header
        class="pa-0"
        color="transparent"
        hide-actions
      >
        <template v-slot:default="{ open }">
          <v-col class="pa-0">
            {{ $t("SEARCH & FILTERS") }}
            <v-icon>{{ `mdi-chevron-double-${open ? 'up' : 'down'}` }}</v-icon>
          </v-col>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pa-0">
        <v-card>
          <v-card-text>
            <v-text-field
              v-model="search"
              append-icon="mdi-feature-search-outline"
              :label="$t('Search')"
              single-line
              hide-details
              outlined
              dense
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="clear"
            >
              {{ $t('Clear All') }}
            </v-btn>
            <v-btn
              color="primary"
              @click="apply"
            >
              {{ $t('Apply') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'SearchUser',
  data () {
    return {
      search: ''
    }
  },
  methods: {
    emit () {
      this.$emit('input', this.search)
    },
    apply () {
      this.emit()
    },
    clear () {
      this.search = ''
      this.emit()
    }
  }
}
</script>
