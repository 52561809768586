<template>
  <v-btn
    dark
    color="orange darken-3"
    to="/users/new"
  >
    <v-icon left>
      mdi-plus
    </v-icon>
    {{ this.$t('New user') }}
  </v-btn>
</template>

<script>
export default {
  name: 'NewUserButton'
}
</script>
