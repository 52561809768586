<template>
  <v-snackbar
    v-model="show"
    app
    class="ma-0"
    :color="color"
    :timeout="4000"
    tile
  >
    <span style="text-transform: capitalize;">{{ message }}</span>
    <v-btn
      x-small
      class="ml-2"
      icon
      fab
      @click="show = false"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      message: '',
      color: ''
    }
  },

  created () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'showMessage') {
        this.message = state.snackbarMessage
        this.color = state.snackbarColor
        this.show = true
      }
    })
  }
}
</script>
