import Amplify, { Auth, I18n } from 'aws-amplify'

Amplify.configure({
  Auth: {
    region: process.env.VUE_APP_COGNITO_REGION,
    userPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    mandatorySignIn: false,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    cookieStorage: { // https://developer.chrome.com/extensions/cookies
      name: 'Orpak1',
      domain: process.env.VUE_APP_COGNITO_COOKIE_DOMAIN,
      secure: process.env.VUE_APP_COGNITO_COOKIE_SECURE === 'true',
      path: '/',
      expires: 3650
    }
  }
})

// Overwrite error messages
I18n.putVocabularies({
  en: {
    'Custom auth lambda trigger is not configured for the user pool.': 'Password cannot be empty',
    '1 validation error detected: Value at "password" failed to satisfy constraint: Member must have length greater than or equal to 6': 'Password not long enough',
    'Password did not conform with policy: Password not long enough': 'Password not long enough',
    'Username/client id combination not found.': 'User not found',
    'CUSTOM_AUTH is not enabled for the client.': 'Password cannot be empty'
  }
})

export default {
  awsconfig: Auth.configure()
}
