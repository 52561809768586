import { getters } from '@/store/getters'
import { moduleEntities } from '@/store/modules/entities'
import { moduleTenants } from '@/store/modules/tenants'
import { moduleUsers } from '@/store/modules/users'
import { mutations } from '@/store/mutations'
import { actions } from '@/store/actions'
import { state } from '@/store/state'
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  getters,
  modules: {
    moduleUsers,
    moduleEntities,
    moduleTenants
  },
  actions
})
