<template>
  <v-img
    v-if="loggedIn"
    dark
    src="@/assets/home.jpg"
  >
    <v-container fluid>
      <v-row
        align="center"
        justify="center"
        class="mt-7"
      >
        <v-col
          class="text-center"
          cols="12"
        >
          <h3 class="display-2 font-weight-regular mb-3">
            <div class="color: blue darken-1;">
              {{ $t("welcome") }} {{ $t("to") }}
            </div>
          </h3>
          <h1 class="display-4 font-weight-bold">
            <div class="color: blue darken-1;">
              Gilbarco One
            </div>
          </h1>
        </v-col>
      </v-row>
    </v-container>
  </v-img>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Home',
  computed: {
    ...mapGetters(['loggedIn', 'cognitoInfo'])
  }
}
</script>
