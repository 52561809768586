import Vue from 'vue'
import { arrayToObject } from '@/common/utilities'

const baseURL = process.env.VUE_APP_USER_SERVICE_URL
const entitiesUrl = process.env.VUE_APP_ENTITY_SERVICE_URL

export const actions = {
  createUser: async (
    { rootGetters },
    { username, attributes, primaryEntityId, primaryTenantId }
  ) => {
    try {
      const data = await Vue.axios.post(`${baseURL}/user`, {
        name: username,
        primary_entity: primaryEntityId,
        primary_tenant: primaryTenantId,
        attributes
      })
      var user = data.data.user
      return user
    } catch (error) {
      console.log(error.response.data.message)
      return Promise.reject(error.response.data.message)
    }
  },
  deleteUser: async ({ commit, rootGetters }, { tenantId, user }) => {
    try {
      await Vue.axios.delete(`${baseURL}/user/${user.username}`)
    } catch (error) {
      return Promise.reject(error.response.data.message)
    }
  },
  deleteUsers: async ({ commit, rootGetters }, { tenantId, usernames }) => {
    try {
      for (const username of usernames) {
        await Vue.axios.delete(`${baseURL}/user/${username}`)
      }
    } catch (error) {
      return Promise.reject(error.response.data.message)
    }
  },
  resetUsers: async ({ commit, rootGetters }, { usernames }) => {
    try {
      for (const username of usernames) {
        await Vue.axios.get(`${baseURL}/user/${username}/actions/reset_password`)
      }
    } catch (error) {
      return Promise.reject(error.response.data.message)
    }
  },
  editUser: async (
    { state, rootGetters, dispatch },
    { username, attributes }
  ) => {
    await Vue.axios.post(`${baseURL}/user/${username}`, {
      attributes
    })
  },

  lockUnlockUsers: async (
    { rootGetters }, { usernames, action }) => {
    try {
      if (!['enable', 'disable'].includes(action)) {
        throw Error(`bad action: ${action}`)
      }
      for (var i = 0; i < usernames.length; ++i) {
        await Vue.axios.post(`${baseURL}/user/${usernames[i]}/actions/${action}`, {})
      }
    } catch (error) {
      const err = error.response ? error.response.data.message : error
      console.log(err)
      return Promise.reject(err)
    }
  },

  getUser: async ({ rootGetters }, { username }) => {
    const data = await Vue.axios.get(`${baseURL}/user/${username}`)
    const user = await parseUser(data.data.description)
    return user
  },

  fetchUsers: async ({ commit, rootGetters }, tenantId) => {
    try {
      const tenant = tenantId || rootGetters.currentTenantId

      const data = await Vue.axios.get(`${entitiesUrl}/tenant/${tenant}/actions/get_users_for_user_entities/${rootGetters.userName}`)
      var readWriteUsers = data.data.read_write_users
      var readOnlyUsers = data.data.read_only_users

      if (readWriteUsers) {
        readWriteUsers = await parseUsers(readWriteUsers)
      }
      if (readOnlyUsers) {
        readOnlyUsers = await parseUsers(readOnlyUsers)
      }

      console.log('fetchUsers (readWrite) for tenant', tenant, ':', readWriteUsers)
      console.log('fetchUsers (readOnly) for tenant', tenant, ':', readOnlyUsers)
      commit('setReadWriteUsers', { tenant: tenant, users: readWriteUsers || [] })
      commit('setReadOnlyUsers', { tenant: tenant, users: readOnlyUsers || [] })
    } catch (error) {
      console.log(error)
    }
  },
  fetchAllUsers: async ({ commit, rootGetters }) => {
    try {
      var allUsers = []
      var nextToken = 'initial'
      while (nextToken) {
        console.log('nextToken', nextToken)
        const data = await Vue.axios.get(`${baseURL}/user`, {
          params: {
            next_token: (nextToken === 'initial') ? '' : nextToken
          }
        })
        allUsers = [...allUsers, ...data.data.users]
        nextToken = data.data.next_token
      }
      if (allUsers.length > 0) {
        allUsers = await parseUsers(allUsers)
      }

      commit('setAllUsers', { users: allUsers || [] })
    } catch (error) {
      console.log(error)
    }
  },

  fetchGroups: async ({ commit, rootGetters }) => {
    try {
      const data = await Vue.axios.get(`${baseURL}/group`)
      var groups = data.data.groups
      commit('setGroups', groups)
    } catch (error) {
      console.log(error)
    }
  }
}

const parseUsers = async (users) => {
  for (var i = 0; i < users.length; ++i) {
    const user = await parseUser(users[i])
    users[i] = {
      ...user
    }
  }
  return users
}

const parseUser = async (user) => {
  const attrs = arrayToObject(user.attributes)
  user = {
    username: user.name,
    ...user,
    ...attrs
  }
  delete user.attributes
  return user
}
