<template>
  <v-menu
    v-model="menu"
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        slot="activator"
        class="text-none"
        style="background: transparent"
        depressed
        v-bind="attrs"
        v-on="on"
      >
        <v-icon class="mr-3">
          mdi-store
        </v-icon>
        {{ tenantName }}
        <v-icon>{{ `mdi-menu-${menu ? 'up': 'down'}` }}</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          autofocus
          clearable
        />
      </v-card-title>
      <v-data-table
        :search="search"
        :headers="headers"
        :items="tenantsList"
        :items-per-page="15"
        @click:row="selectTenant"
      >
        <template v-slot:[`item.creation_date`]="{ item }">
          {{ formatDate(item.creation_date) }}
        </template>
      </v-data-table>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatDate } from '@/common/formatters'
export default {
  name: 'TenantMenu',
  components: {
  },
  data () {
    return {
      menu: false,
      search: ''
    }
  },
  computed: {
    ...mapGetters(['currentTenantId', 'tenantsList']),
    tenantName () {
      const tenant = this.tenantsList.find(el => el.id === this.currentTenantId)
      if (!tenant) return
      return tenant.name
    },
    headers () {
      return [
        { value: 'name', text: 'Name' },
        { value: 'creation_date', text: 'Created on' }
      ]
      // const tenant = this.tenantsList.find(el => el.id === this.currentTenantId)
      // const keys = Object.keys(tenant)
      // const headers = keys.map(el => {
      //   return {
      //     value: el,
      //     text: el
      //   }
      // })
      // console.log('headers:', headers)
      // return headers
    }
  },
  methods: {
    selectTenant (value) {
      this.$store.commit('setCurrentTenantId', value.id)
      this.menu = false
    },
    formatDate: formatDate
  }
}
</script>
