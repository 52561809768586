const fetchFile = ({ file, resolve, reject }) => {
  var xhr = new XMLHttpRequest()
  xhr.open('GET', file + '?t=' + new Date().getTime())
  xhr.onreadystatechange = function () {
    var DONE = 4
    var OK = 200
    if (xhr.readyState === DONE) {
      if (xhr.status === OK) {
        resolve(xhr.responseText)
      } else {
        reject('Error: ' + xhr.status)
      }
    }
  }
  xhr.send(null)
}

const fetchIndexHTMLFile = () => {
  return new Promise((resolve, reject) => {
    fetchFile({ file: 'index.html', resolve, reject })
  })
}

let oldSource = null
const isNewerVersionAvailable = async () => {
  const freshSource = await fetchIndexHTMLFile()
  if (oldSource === null) {
    oldSource = freshSource
    return false
  }
  if (oldSource === freshSource) {
    return false
  }
  oldSource = freshSource
  return true
}

module.exports = {
  isNewerVersionAvailable
}
