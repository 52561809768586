import { actions } from '@/store/modules/users/users-actions'
import { getters } from '@/store/modules/users/users-getters'
import { mutations } from '@/store/modules/users/users-mutations'
import { state } from '@/store/modules/users/users-state'
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export const moduleUsers = {
  state,
  mutations,
  getters,
  actions
}
