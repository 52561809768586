import i18n from '@/plugins/i18n'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  rtl: false,
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  }
})
