export const getDefaultState = () => ({
  entities: [],
  roles: [
    'AnalyticsAdmin',
    'AnalyticsDesigner',
    'AnalyticsReader',
    'EntityAdmin',
    'EntityReader',
    'MiSiteAllReader',
    'MiSiteEquipmentReader',
    'MiSitePricingReader',
    'MiSiteSalesReader',
    'MiSiteWetInvReader'
  ]
})

export const state = getDefaultState()
