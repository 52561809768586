import axios from 'axios'
import { Auth } from 'aws-amplify'
import store from '@/store'

axios.interceptors.request.use(
  async (request) => {
    try {
      const session = await Auth.currentSession()
      const token = session.getAccessToken().getJwtToken()
      request.headers.common.Authorization = `Bearer ${token}`
      return request
    } catch (e) {
      store.dispatch('resetState')
      return Promise.reject(e)
    }
  }
)

export default axios
