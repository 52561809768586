import { arrayToObject } from '@/common/utilities'
import Vue from 'vue'

const baseURL = process.env.VUE_APP_ENTITY_SERVICE_URL

export const actions = {
  enforce: async ({ rootGetters }, { userName, tenantId, entity, action }) => {
    try {
      const enforce = await Vue.axios.post(`${baseURL}/actions/enforce`, {
        tenant: tenantId,
        subject: userName,
        object: entity,
        action: action
      })
      // console.log(`Enforce=${enforce.data.allowed}  domain: ${tenantId}, subject: ${userName}, object: ${entity}, action: ${action}`)
      return !!(enforce.data.allowed)
    } catch (error) {
      console.log('enforce:', {
        tenant: tenantId,
        subject: userName,
        object: entity,
        action: action
      }, error.response ? error.response.data.message : error)
      return false
    }
  },
  getTenantSites: async ({ rootGetters }, { tenantId, flatResponse = false }) => {
    try {
      var data = await Vue.axios.get(`${baseURL}/tenant/${tenantId}/entity/actions/list_for_user/${rootGetters.userName}?filterByType=true&entity_type=SITE${flatResponse ? '&flatResponse=true' : ''}`)
      var entities = data.data.entities || []
      return entities
    } catch (error) {
      console.log(error.response ? error.response.data.message : error)
    }
  },
  fetchEntities: async ({ commit, rootGetters }) => {
    try {
      var data = await Vue.axios.get(`${baseURL}/tenant/${rootGetters.currentTenantId}/entity/actions/list_for_user/${rootGetters.userName}`)
      var entities = data.data.entities
      entities = calculateSiteNumber(entities)
      commit('setEntities', entities || [])
    } catch (error) {
      console.log(error.response ? error.response.data.message : error)
    }
  },
  getEntities: async ({ commit, rootGetters }, { entityType = '', skipRoles = false }) => {
    try {
      // const filterByTypeParams = entityType ? `&filterByType=true&entity_type=${entityType}` : ''
      // const params = `?skip_roles=${skipRoles}${filterByTypeParams}`
      const params = ''
      var data = await Vue.axios.get(`${baseURL}/tenant/${rootGetters.currentTenantId}/entity/actions/list_for_user/${rootGetters.userName}${params}`)
      return data.data.entities
    } catch (error) {
      console.log(error.response ? error.response.data.message : error)
      throw error
    }
  },
  getEntitiesPerUser: async ({ rootGetters }, { username }) => {
    try {
      var data = await Vue.axios.get(`${baseURL}/tenant/${rootGetters.currentTenantId}/entity/actions/list_for_user/${username}?explicit=true&flat_response=true`)
      var entities = data.data.entities
      // if (entities) {
      //   for (const item of entities) {
      //     data = await authAxios.get(`/tenant/${rootGetters.currentTenantId}/entity/${item.entity.parent_entity_id}/users`)
      //     const parentUsers = data.data.users
      //     defineUsersInheritance([item], parentUsers, username)
      //   }
      // }
      return entities
    } catch (error) {
      console.log(error)
    }
  },
  getUsersPerEntity: async ({ rootGetters }, { entityId, tenantId }) => {
    var data = await Vue.axios.get(`${baseURL}/tenant/${tenantId}/entity/${entityId}/users`)
    // const users = data.data.users
    const users = parseUsers(data.data.users)
    // console.log('[getUsersPerEntity] users:', users)
    // if (!users) return
    // data = await authAxios.get(`/tenant/${tenantId}/entity/${entityId}`)
    // const entity = data.data.entity
    // data = await authAxios.get(`/tenant/${tenantId}/entity/${entity.parent_entity_id}/users`)
    // const parentUsers = data.data.users
    // defineUsersInheritance(users, parentUsers)
    return users
  },
  getTotalChildEntities: async ({ rootGetters }, { parentEntityId, entityType = '' }) => {
    const params = entityType ? `?filterByType=true&entity_type=${entityType}` : ''
    const { data } = await Vue.axios.get(`${baseURL}/tenant/${rootGetters.currentTenantId}/entity/actions/list_for_entity/${parentEntityId}/total${params}`)
    return data
  },
  getChildEntities: async ({ rootGetters }, { parentEntityId, limit = 0, after = '', entityType = '' }) => {
    // const filterByTypeParams = entityType ? `filterByType=true&entity_type=${entityType}` : ''
    // const pagingParams = `limit=${limit}&after=${after}`
    // const params = `?skip_roles=true&${filterByTypeParams}&${pagingParams}`
    const params = ''
    const { data } = await Vue.axios.get(`${baseURL}/tenant/${rootGetters.currentTenantId}/entity/actions/list_for_entity/${parentEntityId}${params}`)
    return data
  },
  addUserToEntity: async ({ rootGetters }, { tenantId, entityId, role, username }) => {
    try {
      const tenant = (tenantId) || rootGetters.currentTenantId
      console.log('addUserToEntity:', `/${tenant}/entity/${entityId}/role/${role}/user/${username}`)
      await Vue.axios.post(`${baseURL}/${tenant}/entity/${entityId}/role/${role}/user/${username}`)
    } catch (error) {
      console.log(error)
    }
  },
  deleteRoleFromUser: async ({ state, rootGetters }, { entityId, role, username, tenantId }) => {
    try {
      const tenant = (tenantId) || rootGetters.currentTenantId
      await Vue.axios.delete(`${baseURL}/${tenant}/entity/${entityId}/role/${role}/user/${username}`)
    } catch (error) {
      console.log(error.response.data.message)
      return Promise.reject(error.response.data.message)
    }
  },
  addEntity: async ({ rootGetters }, { parentEntityId, type, name, description }) => {
    try {
      const data = await Vue.axios.post(`${baseURL}/tenant/${rootGetters.currentTenantId}/entity`, {
        name,
        description,
        parent_entity_id: parentEntityId,
        tenant_id: rootGetters.currentTenantId,
        entity_type: type
      })
      return data
    } catch (error) {
      console.log(error.response.data.message)
      return Promise.reject(error.response.data.message)
    }
  },
  addEntitiesInBulk: async ({ rootGetters }, { parentEntityId, type, names }) => {
    try {
      const data = await Vue.axios.post(`${baseURL}/tenant/${rootGetters.currentTenantId}/entity/${parentEntityId}/actions/bulk_create`, {
        parent_entity_id: parentEntityId,
        tenant_id: rootGetters.currentTenantId,
        type,
        names
      })
      return data
    } catch (error) {
      console.log(error.response.data.message)
      return Promise.reject(error.response.data.message)
    }
  },
  getEntity: async ({ rootGetters }, { tenantId, entityId }) => {
    try {
      const data = await Vue.axios.get(`${baseURL}/tenant/${tenantId}/entity/${entityId}`)
      var entity = data.data.entity
      entity = await parseEntity(entity)
      return entity
    } catch (error) {
      console.log(error)
    }
  },
  editEntity: async ({ state, rootGetters },
    {
      tenantId,
      entityId,
      name,
      description,
      attributes
    }
  ) => {
    try {
      const data = await Vue.axios.post(`${baseURL}/tenant/${tenantId}/entity/${entityId}`, {
        name,
        description,
        attributes
      })
      const entity = parseEntity(data.data.entity)
      return entity
    } catch (error) {
      return Promise.reject(error.response.data.message)
    }
  },
  deleteEntity: async ({ rootGetters }, { entityId, tenantId }) => {
    try {
      await Vue.axios.delete(`${baseURL}/tenant/${tenantId}/entity/${entityId}`)
    } catch (error) {
      return Promise.reject(error.response.data.message)
    }
  }
}

function addSiteNumber (obj) {
  let count = 0
  for (let i = 0; i < obj.child_entities.length; i++) {
    const child = obj.child_entities[i]
    if (child.entity.entity_type === 'SITE') {
      child.nameAndNumber = child.entity.name
      count++
    } else if (child.entity.entity_type === 'GROUP') {
      count += addSiteNumber(child)
    }
  }
  obj.siteNumber = count
  obj.nameAndNumber = count && count > 0 ? `${obj.entity.name} (${obj.siteNumber})` : obj.entity.name
  return count
}

function calculateSiteNumber (tenants) {
  for (let i = 0; i < tenants.length; i++) {
    addSiteNumber(tenants[i])
  }
  return tenants
}

const parseUsers = (users) => {
  if (!users) return
  return users.map(el => {
    const username = el.user.name
    return {
      username,
      ...el.user,
      ...arrayToObject(el.user.attributes),
      roles: el.roles,
      explicit_roles: el.explicit_roles
    }
  })
}

// const parseEntities = (entities) => {
//   return entities.map(el => {
//     return {
//       ...el.entity,
//       roles: el.roles
//     }
//   })
// }

const parseEntity = async (entity) => {
  if (!entity.attributes) {
    return entity
  }
  const attrs = arrayToObject(entity.attributes)
  entity = {
    ...entity,
    ...attrs
  }
  delete entity.attributes
  return entity
}

// const defineUsersInheritance = (users, parentUsers, username = null) => {
//   for (const user of users) {
//     var newRoles = []
//     if (user.roles) {
//       for (var role of user.roles) {
//         var isInherited
//         if (!parentUsers) {
//           isInherited = false
//         } else {
//           const name = user.user_name || username
//           const found = parentUsers.find(el => el.user_name === name)
//           if (!found) {
//             isInherited = false
//           } else {
//             isInherited = isRoleInherited(role, found.roles)
//           }
//         }
//         newRoles.push({
//           text: role,
//           isInherited
//         })
//       }
//     }
//     user.roles = newRoles
//   }
// }

// const isRoleInherited = (role, userRolesInParent) => {
//   if (!userRolesInParent) return false
//   return userRolesInParent.includes(role)
// }
