import { getDefaultState } from './users-state'

export const mutations = {
  setReadWriteUsers: (state, { tenant, users }) => {
    state.users[tenant] = users
  },
  setReadOnlyUsers: (state, { tenant, users }) => {
    const filteredUsers = users.filter(u => !state.users[tenant].includes(u))
    state.readOnlyUsers[tenant] = filteredUsers
  },
  setAllUsers: (state, { users }) => {
    state.allUsers = users
  },
  setGroups: (state, payload) => {
    state.groups = payload
  },
  resetUsers: (state) => {
    Object.assign(state, getDefaultState())
  }
}
