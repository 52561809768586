export const getters = {
  cognitoInfo: state => state.cognitoInfo,
  loggedIn: state => state.loggedIn,
  loadingState: state => state.loadingState,
  errorLoadingState: state => state.errorLoadingState,
  locales: state => state.locales,
  temperatures: state => state.temperatures,
  heights: state => state.heights,
  volumes: state => state.volumes,
  currencies: state => state.currencies,
  rtl: state => state.rtl,
  userName: state => state.userName,
  entity: state => state.entity,
  currentLocale: state => state.currentLocale
}
