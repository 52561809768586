import { arrayToObject } from '@/common/utilities'
import Vue from 'vue'

const baseURL = process.env.VUE_APP_ENTITY_SERVICE_URL

export const actions = {
  fetchTenantsList: async ({ commit, state, rootState }, payload) => {
    try {
      const data = await Vue.axios.get(`${baseURL}/tenant`)
      var tenants = data.data.tenants
      console.log('tenantsList:', tenants)
      commit('setTenantsList', tenants || [])
    } catch (error) {
      console.log(error.response ? error.response.data.message : error)
    }
  },
  fetchTenantGroups: async ({ commit, rootState }) => {
    try {
      var data = await Vue.axios.get(`${baseURL}/tenant_group/actions/list_with_members`)
      var tenantGroupsList = data.data.entities
      console.log('fetchTenantGroups', tenantGroupsList)
      commit('setTenantGroupsList', tenantGroupsList || [])
    } catch (error) {
      console.log(error.response ? error.response.data.message : error)
    }
  },
  getTenantGroupsPerUser: async ({ state, rootState }, { username }) => {
    try {
      var data = await Vue.axios.get(`${baseURL}/tenant_group/actions/list_for_user/${username}`)
      var tenantGroupsList = data.data.entities
      console.log('getTenantGroupsPerUser user:', username, tenantGroupsList)
      return tenantGroupsList
    } catch (error) {
      console.log(error.response ? error.response.data.message : error)
    }
  },
  getUsersPerTenantGroup: async ({ state, rootState }, payload) => {
    var data = await Vue.axios.get(`${baseURL}/tenant_group/${payload}/actions/list_users`)
    const users = data.data.users
    return users
  },
  addUserToTenantGroup: async ({ state, rootState }, { tenantGroupId, entityId, role, username }) => {
    try {
      console.log(`/${tenantGroupId}/entity/${entityId}/role/${role}/user/${username}`)
      await Vue.axios.post(`${baseURL}/${tenantGroupId}/entity/${entityId}/role/${role}/user/${username}`)
    } catch (error) {
      console.log(error)
    }
  },
  deleteRoleFromUserOnTenantGroup: async ({ state, rootState }, { tenantGroupId, entityId, role, username }) => {
    try {
      await Vue.axios.delete(`${baseURL}/${tenantGroupId}/entity/${entityId}/role/${role}/user/${username}`)
    } catch (error) {
      console.log(error.response.data.message)
      return Promise.reject(error.response.data.message)
    }
  },
  addTenantToTenantGroup: async ({ state, rootState }, { tenantGroupId, newTenantId }) => {
    try {
      const data = await Vue.axios.get(`${baseURL}/tenant_group/${tenantGroupId}`)
      const tenantGroup = data.data

      console.log('tenantGroup before:', tenantGroup)
      for (const att of tenantGroup.attributes) {
        if (att.name === 'tenants') {
          const value = att.value
          if (att.value) {
            const list = value.split(',')
            list.push(newTenantId)
            att.value = list.join(',')
          } else {
            att.value = newTenantId
          }
        }
      }
      console.log('tenantGroup after:', tenantGroup)

      const name = tenantGroup.name
      const description = tenantGroup.description
      const attributes = tenantGroup.attributes
      const updatedTenantGroup = await Vue.axios.post(`${baseURL}/tenant_group/${tenantGroup.id}`, {
        name,
        description,
        attributes
      })
      return updatedTenantGroup
    } catch (error) {
      console.log(error)
    }
  },
  getTenantGroup: async ({ state, rootState }, { id }) => {
    try {
      const data = await Vue.axios.get(`${baseURL}/tenant_group/${id}`)
      var tenantGroup = data.data
      tenantGroup = await parseEntity(tenantGroup)
      return tenantGroup
    } catch (error) {
      console.log(error)
    }
  },
  editTenantGroup: async ({ rootState },
    {
      id,
      name,
      description,
      attributes
    }
  ) => {
    try {
      console.log(`/tenant_group/${id}`, {
        name,
        description,
        attributes
      })
      await Vue.axios.post(`${baseURL}/tenant_group/${id}`, {
        name,
        description,
        attributes
      })
    } catch (error) {
      return Promise.reject(error.response.data.message)
    }
  },
  createTenant: async ({ rootState }, { tenantName, description, attributes, tenantGroupId }) => {
    try {
      const tenant = await Vue.axios.post(`${baseURL}/tenant`, {
        tenant_name: tenantName,
        tenant_group_id: tenantGroupId,
        attributes,
        description
      })
      console.log('new tenant:', tenant)
      // console.log(`/tenant/${tenant.data.id}/entity/${tenant.data.id}`)
      // console.log('name:', name, 'description:', description, 'attributes:', attributes)
      // const data = await authAxios.post(`/tenant/${tenant.data.id}/entity/${tenant.data.id}`, {
      //   name,
      //   description,
      //   attributes
      // })
      return tenant.data.id
    } catch (error) {
      console.log(error)
      return Promise.reject(error.response.data.message)
    }
  },
  createTenantGroup: async ({ rootState }, { name, description, attributes }) => {
    try {
      const tenantGroup = await Vue.axios.post(`${baseURL}/tenant_group`, {
        tenant_group_name: name
      })
      const id = tenantGroup.data.id
      const updatedTenantGroup = await Vue.axios.post(`${baseURL}/tenant_group/${id}`, {
        name,
        description,
        attributes
      })
      return updatedTenantGroup
    } catch (error) {
      return Promise.reject(error.response.data.message)
    }
  },
  deleteTenantGroup: async ({ rootState }, { id }) => {
    try {
      await Vue.axios.delete(`${baseURL}/tenant_group/${id}`)
    } catch (error) {
      console.log(error)
      return Promise.reject(error.response.data.message)
    }
  },
  deleteTenant: async ({ rootState }, { id }) => {
    try {
      await Vue.axios.delete(`${baseURL}/tenant/${id}`)
    } catch (error) {
      console.log(error)
      return Promise.reject(error.response.data.message)
    }
  },
  getTenant: async ({ rootGetters }, { tenantId }) => {
    try {
      const data = await Vue.axios.get(`${baseURL}/tenant/${tenantId}/entity/${tenantId}`)
      return data.data
    } catch (error) {
      console.log(error)
    }
  }
}

// const parseEntities = (entities) => {
//   return entities.map(el => {
//     return {
//       ...el.entity,
//       roles: el.roles
//     }
//   })
// }

const parseEntity = async (entity) => {
  if (!entity.attributes) {
    return entity
  }
  const attrs = arrayToObject(entity.attributes)
  entity = {
    ...entity,
    ...attrs
  }
  delete entity.attributes
  return entity
}

// const defineUsersInheritance = (users, parentUsers, username = null) => {
//   for (const user of users) {
//     var newRoles = []
//     for (var role of user.roles) {
//       var isInherited
//       if (!parentUsers) {
//         isInherited = false
//       } else {
//         const name = user.user_name || username
//         const found = parentUsers.find(el => el.user_name === name)
//         if (!found) {
//           isInherited = false
//         } else {
//           isInherited = isRoleInherited(role, found.roles)
//         }
//       }
//       newRoles.push({
//         text: role,
//         isInherited
//       })
//     }
//     user.roles = newRoles
//   }
// }

// const isRoleInherited = (role, userRolesInParent) => {
//   return userRolesInParent.includes(role)
// }
