import App from '@/App.vue'
import awsconfig from '@/app/auth'
// import CustomerManagement from '@/components/customers/Management'
// import SiteManagement from '@/components/sites/Management'
import UserManagement from '@/components/users/UserManagement'
import axios from '@/plugins/axios'
import i18n from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'
import router from '@/router'
import store from '@/store'
import {
  applyPolyfills,
  defineCustomElements
} from '@aws-amplify/ui-components/loader'
import Amplify from 'aws-amplify'
import Mapbox from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import Vue from 'vue'
import VueAxios from 'vue-axios'
import FlagIcon from 'vue-flag-icon'
import VueMapbox from 'vue-mapbox'
import VuetifyConfirm from 'vuetify-confirm'
import { isNewerVersionAvailable } from '@/utils'

Amplify.configure(awsconfig)
applyPolyfills().then(() => {
  defineCustomElements(window)
})

Vue.config.ignoredElements = [/amplify-\w*/]

Vue.component('UserManagement', UserManagement)
// Vue.component('CustomerManagement', CustomerManagement)
// Vue.component('SiteManagement', SiteManagement)

Vue.use(VueMapbox, { mapboxgl: Mapbox })
Vue.use(VuetifyConfirm, { vuetify })
Vue.use(FlagIcon)
Vue.use(VueAxios, axios)
Vue.config.productionTip = false

export const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  created () {
    setInterval(async () => {
      const newVersionAvailable = await isNewerVersionAvailable()
      if (newVersionAvailable) {
        window.location.reload()
      }
    }, 5 * 60 * 1000) // every 5 minutes
  },
  render: h => h(App)
}).$mount('#app')
