<template>
  <v-menu
    v-model="menu"
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        slot="activator"
        class="text-none"
        style="background: transparent"
        depressed
        v-bind="attrs"
        v-on="on"
      >
        <v-icon class="mr-3">
          mdi-account-outline
        </v-icon>
        {{ cognitoInfo['cognito:username'] }}
        <v-icon>{{ `mdi-menu-${menu ? 'up': 'down'}` }}</v-icon>
      </v-btn>
    </template>
    <v-list
      dense
    >
      <v-list-item>
        {{ cognitoInfo.email }}
      </v-list-item>
      <v-list-item>
        <locale-select @change="menu = false" />
      </v-list-item>
      <v-list-item
        v-for="(item, index) in actions"
        :key="index"
        dense
        :to="item.route"
      >
        <v-list-item-content>
          {{ item.text }}
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import LocaleSelect from '@/components/common/LocaleSelect'
export default {
  name: 'UserMenu',
  components: {
    LocaleSelect
  },
  data () {
    return {
      menu: false
    }
  },
  computed: {
    ...mapGetters(['cognitoInfo']),
    actions () {
      return [
        { text: this.$t('logout'), route: '/logout', icon: 'mdi-exit-to-app' }
      ]
    }
  }
}
</script>
