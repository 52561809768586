<template>
  <v-app>
    <v-navigation-drawer
      v-if="loggedIn"
      v-model="drawer"
      app
      :right="rtl"
      clipped
      dark
      permanent
      fixed
      :mini-variant.sync="mini"
      src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
    >
      <v-list
        subheader
        two-line
      >
        <v-list-item
          link
          to="/"
        >
          <v-list-item-action>
            <v-icon>mdi-home-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('Home') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="currentTenantId != 'system'"
          link
          to="/sites"
        >
          <v-list-item-action>
            <v-icon>mdi-sitemap</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('Sites') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="toShowUsers"
          link
          to="/users"
        >
          <v-list-item-action>
            <v-icon>mdi-account-circle-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('Users') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-divider />
        <v-subheader>Apps</v-subheader> -->

        <!-- <v-list-item
          :href="misiteURL"
          target="_blank"
        >
          <v-list-item-action>
            <v-icon>mdi-gas-station</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>MiSite Web</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <!-- <v-list-item
          :href="reportGeneratorURL"
          target="_blank"
        >
          <v-list-item-action>
            <v-icon>mdi-clipboard-text-multiple-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Analytics Module</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <div v-if="currentTenantId === 'system'">
          <v-divider />
          <v-subheader>System</v-subheader>

          <v-list-item
            link
            to="/tenants"
          >
            <v-list-item-action>
              <v-icon>mdi-store</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('Companies') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      :clipped-left="!rtl"
      :clipped-right="rtl"
      color="primary"
      dark
      dense
    >
      <v-app-bar-nav-icon
        v-if="loggedIn"
        @click.stop="mini = !mini"
      />
      <v-avatar
        class="profile"
        width="164"
        tile
      >
        <v-img src="@/assets/gvr-logo.png" />
      </v-avatar>
      <v-spacer />
      <v-divider
        v-if="loggedIn"
        vertical
        class="mx-3"
      />
      <div v-if="loggedIn">
        <TenantMenu v-if="tenantsList.length" />
        <span
          v-else
          class="v-btn__content px-4 "
        >
          <v-icon class="mr-3">
            mdi-store
          </v-icon>
          {{ currentTenantName }}
        </span>
      </div>
      <v-divider
        v-if="loggedIn"
        vertical
        class="mx-3"
      />
      <user-menu v-if="loggedIn" />
    </v-app-bar>
    <v-main>
      <div v-if="loggedIn">
        <router-view />
        <message-snackbar />
      </div>
      <div v-else>
        <v-row justify="center">
          <amplify-authenticator>
            <amplify-sign-in
              slot="sign-in"
              hide-sign-up="true"
            />
          </amplify-authenticator>
        </v-row>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import MessageSnackbar from '@/components/common/MessageSnackbar.vue'
import TenantMenu from '@/components/tenants/TenantMenu'
import UserMenu from '@/components/users/UserMenu'
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import { Auth } from 'aws-amplify'
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  components: {
    UserMenu,
    TenantMenu,
    MessageSnackbar
  },
  data: function () {
    return {

      misiteURL: process.env.VUE_APP_MISITE_URL,
      reportGeneratorURL: process.env.VUE_APP_REPORTGEN_URL,

      drawer: null,
      mini: false,
      user: undefined,
      authState: undefined,
      toShowUsers: false,
      checkingUsers: false,
      currentTenantName: ''
    }
  },
  computed: {
    ...mapGetters([
      'loggedIn',
      'rtl',
      'tenantsList',
      'currentTenantId',
      'tenantGroupsList',
      'users',
      'userName'
    ]),
    systemMode () {
      return this.tenantGroupsList.length
    }
  },
  watch: {
    currentTenantId: {
      immediate: false,
      async handler (value) {
        if (!this.checkingUsers) this.setCanSeeUsers()
        if (value && value !== 'system') {
          const currentTenant = await this.$store.dispatch('getTenant', { tenantId: this.currentTenantId })
          this.currentTenantName = currentTenant.entity.name
          currentTenant.child_entities = []
          this.$store.commit(
            'setCurrentTenant',
            currentTenant
          )
        }
      }
    },
    userName: {
      immediate: false,
      handler () {
        if (!this.checkingUsers) this.setCanSeeUsers()
      }
    }
  },
  created () {
    onAuthUIStateChange(async (authState, authData) => {
      console.log('**** onAuthUIStateChange **** authState:', authState)
      if (this && authState === this.authState) return
      await this.loginChanged(authState, authData)
    })
  },
  beforeDestroy () {
    return onAuthUIStateChange
  },
  methods: {
    async setCanSeeUsers () {
      this.toShowUsers = false
      console.log('this.currentTenantId:', this.currentTenantId)
      if (this.checkingUsers || !this.currentTenantId) return
      this.checkingUsers = true
      try {
        var usersCreate = await this.$store.dispatch('enforce', {
          userName: this.userName,
          tenantId: this.currentTenantId,
          action: 'users-create',
          entity: this.currentTenantId
        })
        if (usersCreate) {
          this.toShowUsers = true
          return
        }
        var userEntities = await this.$store.dispatch('getEntitiesPerUser', {
          username: this.userName
        })
        var containsEntityAdmin = userEntities
          ? userEntities
            .map((el) => el.roles)
            .some((el) => el.includes('EntityAdmin'))
          : null
        if (containsEntityAdmin) {
          this.toShowUsers = true
        }
      } catch (error) {
        this.$store.commit('showMessage', { message: error, color: 'error' })
      } finally {
        this.checkingUsers = false
      }
    },
    async loginChanged (authState, authData) {
      if (!this) return
      this.authState = authState
      this.user = authData
      if (authState === 'signedin') {
        await this.login()
      }
    },
    async login () {
      const session = await Auth.currentSession()

      var cognitoInfo = session.getIdToken().decodePayload()
      await this.$store.commit('setCognitoInfo', cognitoInfo)
      await this.$store.commit('setUserName', cognitoInfo['cognito:username'])
      await this.$store.commit(
        'setUserPrimaryTenant',
        cognitoInfo['custom:primary-tenant']
      )
      await this.$store.commit('setCurrentLocale', cognitoInfo.locale)
      await this.switchLocale(cognitoInfo.locale)
      await this.$store.dispatch('fetchTenantsList')
      await this.$store.dispatch('fetchTenantGroups')

      if (
        sessionStorage.currentTenantId &&
        this.tenantsList.some((el) => el.id === sessionStorage.currentTenantId)
      ) {
        await this.$store.commit(
          'setCurrentTenantId',
          sessionStorage.currentTenantId
        )
      } else {
        await this.$store.commit(
          'setCurrentTenantId',
          cognitoInfo['custom:primary-tenant']
        )

        // TEMP till all users have primary tenant
        if (
          !cognitoInfo['custom:primary-tenant'] &&
          this.tenantsList.length > 0
        ) {
          console.log(
            'user without primary-tenant. setting it to:',
            this.tenantsList[0]
          )
          await this.$store.commit('setCurrentTenantId', this.tenantsList[0])
        }
        // TEMP till we we fix deletion of tenant with existing users
        if (
          this.tenantsList.length > 0 &&
          cognitoInfo['custom:primary-tenant'] &&
          !this.tenantsList.some(
            (t) => t.id === cognitoInfo['custom:primary-tenant']
          )
        ) {
          console.log(
            'primary-tenant missing - setting setCurrentTenantId from:',
            this.currentTenantId,
            'to:',
            this.tenantsList[0].id
          )
          await this.$store.commit(
            'setCurrentTenantId',
            this.tenantsList[0].id
          )
        }
      }
      await this.setCanSeeUsers()
      await this.$store.commit('setLoggedIn', true)
    },
    switchLocale (locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale
      }
      const rtlLanguages = ['he']
      const rtl = !!rtlLanguages.find((lang) => locale === lang)
      this.$vuetify.rtl = rtl
      this.$i18n.rtl = rtl
      this.$store.commit('setRtl', rtl)
      this.$emit('change')
    }
  }
}
</script>
