import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const vuetifyMessages = {
    en: {
      $vuetify: {
        noDataText: 'No data available',
        dataIterator: {
          rowsPerPageText: 'Items per page:',
          pageText: '{0}-{1} of {2}',
          loadingText: 'Loading, please wait...',
          noResultsText: 'No results'
        },
        dataTable:
        {
          itemsPerPageText: 'Items per page:',
          pageText: '{0}-{1} of {2}'

        },
        dataFooter: {
          itemsPerPageAll: 'All',
          itemsPerPageText: 'Items per page:',
          pageText: '{0}-{1} of {2}'
        }
      }
    },

    he: {
      $vuetify: {
        noDataText: 'אין מידע זמין',
        dataIterator: {
          rowsPerPageText: 'פרטים לדף:',
          pageText: '{0}-{1} מתוך {2}',
          loadingText: 'טוען, אנא המתן...',
          noResultsText: 'אין תוצאות'
        },
        dataTable:
        {
          itemsPerPageText: 'פרטים לדף:',
          pageText: '{0}-{1} מתוך {2}'
        },
        dataFooter: {
          itemsPerPageAll: 'הכל',
          itemsPerPageText: 'פרטים לדף:',
          pageText: '{0}-{1} מתוך {2}'
        }
      }
    }
  }

  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = {
        ...locales(key),
        ...vuetifyMessages[locale]
      }
    }
  })
  return messages
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  silentTranslationWarn: true
})
