import { getDefaultState } from './tenants-state'

export const mutations = {

  setUserPrimaryTenant: (state, payload) => {
    state.userPrimaryTenant = payload
  },
  setTenantsList: (state, payload) => {
    if (payload && (state.userPrimaryTenant === 'system' || payload.length > 1)) {
      const systemTenant = { name: 'System', id: 'system', creation_date: '1/1/1983' }
      payload = [...payload, systemTenant]
      state.tenantsList = payload.sort((a, b) => a.name > b.name ? 1 : -1)
    }
  },
  setTenantGroupsList: (state, payload) => {
    for (const tenantGroup of payload) {
      if (tenantGroup.child_entities) {
        const newChildEntitiesList = []
        for (const entity of (tenantGroup.child_entities ?? []).filter(a => a.entity).sort((a, b) => a.entity.name > b.entity.name ? 1 : -1)) {
          if (entity.entity) {
            newChildEntitiesList.push(entity)
          }
        }
        tenantGroup.child_entities = newChildEntitiesList
      }
    }
    state.tenantGroupsList = payload.sort((a, b) => a.entity.name > b.entity.name ? 1 : -1)
  },
  setCurrentTenant: (state, payload) => {
    state.currentTenant = payload
  },
  setCurrentTenantId: (state, payload) => {
    state.currentTenantId = payload
    sessionStorage.currentTenantId = payload
  },
  resetTenants: (state) => {
    Object.assign(state, getDefaultState())
  }
}
