export const mutations = {
  showMessage (state, payload) {
    state.snackbarMessage = payload.message
    state.snackbarColor = payload.color
  },
  setLoggedIn: (state, payload) => {
    state.loggedIn = payload
  },
  setLoggedOut: (state, payload) => {
    state.loggedIn = false
    state.cognitoInfo = {}
  },
  setCognitoInfo: (state, payload) => {
    state.cognitoInfo = payload
  },
  setRtl: (state, payload) => {
    state.rtl = payload
  },
  setUserName: (state, payload) => {
    state.userName = payload
  },
  setCurrentLocale: (state, payload) => {
    state.currentLocale = payload
  }
}
