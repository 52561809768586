import { getDefaultState } from './entities-state'

export const mutations = {
  setEntities: (state, payload) => {
    state.entities = payload
  },
  resetEntities: (state) => {
    Object.assign(state, getDefaultState())
  }
}
