export const state = {
  snackbarMessage: '',
  snackbarColor: '',
  cognitoInfo: {},
  loggedIn: false,
  loadingState: true,
  errorLoadingState: false,
  rtl: false,
  userName: null,
  currentLocale: 'en',
  locales: [
    { value: 'en', text: 'English', icon: 'us' },
    { value: 'he', text: 'עברית', icon: 'il' }
  ],
  temperatures: [
    { code: 'c', name: 'Celsius', symbol: '⁰C', displayName: 'c (⁰C)' },
    { code: 'f', name: 'Ferenhight', symbol: '⁰F', displayName: 'f (⁰F)' }
  ],
  heights: [
    { code: 'cm', name: 'Centimeter', symbol: 'cm', displayName: 'cm (cm)' },
    { code: 'inch', name: 'Inch', symbol: 'inch', displayName: 'inch (inch)' },
    { code: 'm', name: 'Meter', symbol: 'm', displayName: 'm (m)' },
    { code: 'mm', name: 'Millimeter', symbol: 'mm', displayName: 'mm (mm)' }
  ],
  volumes: [
    { code: 'l', name: 'Liter', symbol: 'L', displayName: 'l (L)' },
    { code: 'gal', name: 'Gal', symbol: 'Gal', displayName: 'gal (Gal)' },
    { code: 'pnt', name: 'pnt', symbol: 'pnt', displayName: 'pnt (pnt)' },
    { code: 'qrt', name: 'qrt', symbol: 'qrt', displayName: 'qrt (qrt)' }
  ],
  currencies: [
    { code: 'AED', name: 'UAE Dirham', symbol: 'د.إ', displayName: 'AED (د.إ)' },
    { code: 'AFN', name: 'Afghani', symbol: 'Af', displayName: 'AFN (Af)' },
    { code: 'ALL', name: 'Lek', symbol: 'L', displayName: 'ALL (L)' },
    { code: 'AMD', name: 'Armenian Dram', symbol: 'Դ', displayName: 'AMD (Դ)' },
    { code: 'AOA', name: 'Kwanza', symbol: 'Kz', displayName: 'AOA (Kz)' },
    { code: 'ARS', name: 'Argentine Peso', symbol: '$', displayName: 'ARS ($)' },
    { code: 'AUD', name: 'Australian Dollar', symbol: '$', displayName: 'AUD ($)' },
    { code: 'AWG', name: 'Aruban Guilder/Florin', symbol: 'ƒ', displayName: 'AWG (ƒ)' },
    { code: 'AZN', name: 'Azerbaijanian Manat', symbol: 'ман', displayName: 'AZN (ман)' },
    { code: 'BAM', name: 'Konvertibilna Marka', symbol: 'КМ', displayName: 'BAM (КМ)' },
    { code: 'BBD', name: 'Barbados Dollar', symbol: '$', displayName: 'BBD ($)' },
    { code: 'BDT', name: 'Taka', symbol: '৳', displayName: 'BDT (৳)' },
    { code: 'BGN', name: 'Bulgarian Lev', symbol: 'лв', displayName: 'BGN (лв)' },
    { code: 'BHD', name: 'Bahraini Dinar', symbol: 'ب.د', displayName: 'BHD (ب.د)' },
    { code: 'BIF', name: 'Burundi Franc', symbol: '₣', displayName: 'BIF (₣)' },
    { code: 'BMD', name: 'Bermudian Dollar', symbol: '$', displayName: 'BMD ($)' },
    { code: 'BND', name: 'Brunei Dollar', symbol: '$', displayName: 'BND ($)' },
    { code: 'BOB', name: 'Boliviano', symbol: 'Bs.', displayName: 'BOB (Bs.)' },
    { code: 'BRL', name: 'Brazilian Real', symbol: 'R$', displayName: 'BRL (R$)' },
    { code: 'BSD', name: 'Bahamian Dollar', symbol: '$', displayName: 'BSD ($)' },
    { code: 'BWP', name: 'Pula', symbol: 'P', displayName: 'BWP (P)' },
    { code: 'BYN', name: 'Belarusian Ruble', symbol: 'Br', displayName: 'BYN (Br)' },
    { code: 'BZD', name: 'Belize Dollar', symbol: '$', displayName: 'BZD ($)' },
    { code: 'CAD', name: 'Canadian Dollar', symbol: '$', displayName: 'CAD ($)' },
    { code: 'CDF', name: 'Congolese Franc', symbol: '₣', displayName: 'CDF (₣)' },
    { code: 'CHF', name: 'Swiss Franc', symbol: '₣', displayName: 'CHF (₣)' },
    { code: 'CLP', name: 'Chilean Peso', symbol: '$', displayName: 'CLP ($)' },
    { code: 'CNY', name: 'Yuan', symbol: '¥', displayName: 'CNY (¥)' },
    { code: 'COP', name: 'Colombian Peso', symbol: '$', displayName: 'COP ($)' },
    { code: 'CRC', name: 'Costa Rican Colon', symbol: '₡', displayName: 'CRC (₡)' },
    { code: 'CUP', name: 'Cuban Peso', symbol: '$', displayName: 'CUP ($)' },
    { code: 'CVE', name: 'Cape Verde Escudo', symbol: '$', displayName: 'CVE ($)' },
    { code: 'CZK', name: 'Czech Koruna', symbol: 'Kč', displayName: 'CZK (Kč)' },
    { code: 'DJF', name: 'Djibouti Franc', symbol: '₣', displayName: 'DJF (₣)' },
    { code: 'DKK', name: 'Danish Krone', symbol: 'kr', displayName: 'DKK (kr)' },
    { code: 'DOP', name: 'Dominican Peso', symbol: '$', displayName: 'DOP ($)' },
    { code: 'DZD', name: 'Algerian Dinar', symbol: 'د.ج', displayName: 'DZD (د.ج)' },
    { code: 'EGP', name: 'Egyptian Pound', symbol: '£', displayName: 'EGP (£)' },
    { code: 'ERN', name: 'Nakfa', symbol: 'Nfk', displayName: 'ERN (Nfk)' },
    { code: 'EUR', name: 'Euro', symbol: '€', displayName: 'EUR (€)' },
    { code: 'FJD', name: 'Fiji Dollar', symbol: '$', displayName: 'FJD ($)' },
    { code: 'FKP', name: 'Falkland Islands Pound', symbol: '£', displayName: 'FKP (£)' },
    { code: 'GBP', name: 'Pound Sterling', symbol: '£', displayName: 'GBP (£)' },
    { code: 'GEL', name: 'Lari', symbol: 'ლ', displayName: 'GEL (ლ)' },
    { code: 'GIP', name: 'Gibraltar Pound', symbol: '£', displayName: 'GIP (£)' },
    { code: 'GMD', name: 'Dalasi', symbol: 'D', displayName: 'GMD (D)' },
    { code: 'GNF', name: 'Guinea Franc', symbol: '₣', displayName: 'GNF (₣)' },
    { code: 'GTQ', name: 'Quetzal', symbol: 'Q', displayName: 'GTQ (Q)' },
    { code: 'GYD', name: 'Guyana Dollar', symbol: '$', displayName: 'GYD ($)' },
    { code: 'HKD', name: 'Hong Kong Dollar', symbol: '$', displayName: 'HKD ($)' },
    { code: 'HNL', name: 'Lempira', symbol: 'L', displayName: 'HNL (L)' },
    { code: 'HRK', name: 'Croatian Kuna', symbol: 'Kn', displayName: 'HRK (Kn)' },
    { code: 'HTG', name: 'Gourde', symbol: 'G', displayName: 'HTG (G)' },
    { code: 'HUF', name: 'Forint', symbol: 'Ft', displayName: 'HUF (Ft)' },
    { code: 'IDR', name: 'Rupiah', symbol: 'Rp', displayName: 'IDR (Rp)' },
    { code: 'ILS', name: 'New Israeli Shekel', symbol: '₪', displayName: 'ILS (₪)' },
    { code: 'INR', name: 'Indian Rupee', symbol: '₹', displayName: 'INR (₹)' },
    { code: 'IQD', name: 'Iraqi Dinar', symbol: 'ع.د', displayName: 'IQD (ع.د)' },
    { code: 'ISK', name: 'Iceland Krona', symbol: 'Kr', displayName: 'ISK (Kr)' },
    { code: 'JMD', name: 'Jamaican Dollar', symbol: '$', displayName: 'JMD ($)' },
    { code: 'JOD', name: 'Jordanian Dinar', symbol: 'د.ا', displayName: 'JOD (د.ا)' },
    { code: 'JPY', name: 'Yen', symbol: '¥', displayName: 'JPY (¥)' },
    { code: 'KES', name: 'Kenyan Shilling', symbol: 'Sh', displayName: 'KES (Sh)' },
    { code: 'KPW', name: 'North Korean Won', symbol: '₩', displayName: 'KPW (₩)' },
    { code: 'KRW', name: 'South Korean Won', symbol: '₩', displayName: 'KRW (₩)' },
    { code: 'KWD', name: 'Kuwaiti Dinar', symbol: 'د.ك', displayName: 'KWD (د.ك)' },
    { code: 'KYD', name: 'Cayman Islands Dollar', symbol: '$', displayName: 'KYD ($)' },
    { code: 'KZT', name: 'Tenge', symbol: '〒', displayName: 'KZT (〒)' },
    { code: 'LBP', name: 'Lebanese Pound', symbol: 'ل.ل', displayName: 'LBP (ل.ل)' },
    { code: 'LKR', name: 'Sri Lanka Rupee', symbol: 'Rs', displayName: 'LKR (Rs)' },
    { code: 'LRD', name: 'Liberian Dollar', symbol: '$', displayName: 'LRD ($)' },
    { code: 'LSL', name: 'Loti', symbol: 'L', displayName: 'LSL (L)' },
    { code: 'LYD', name: 'Libyan Dinar', symbol: 'ل.د', displayName: 'LYD (ل.د)' },
    { code: 'MAD', name: 'Moroccan Dirham', symbol: 'د.م.', displayName: 'MAD (د.م.)' },
    { code: 'MDL', name: 'Moldovan Leu', symbol: 'L', displayName: 'MDL (L)' },
    { code: 'MKD', name: 'Denar', symbol: 'ден', displayName: 'MKD (ден)' },
    { code: 'MMK', name: 'Kyat', symbol: 'K', displayName: 'MMK (K)' },
    { code: 'MOP', name: 'Pataca', symbol: 'P', displayName: 'MOP (P)' },
    { code: 'MRU', name: 'Ouguiya', symbol: 'UM', displayName: 'MRU (UM)' },
    { code: 'MUR', name: 'Mauritius Rupee', symbol: '₨', displayName: 'MUR (₨)' },
    { code: 'MVR', name: 'Rufiyaa', symbol: 'ރ.', displayName: 'MVR (ރ.)' },
    { code: 'MWK', name: 'Kwacha', symbol: 'MK', displayName: 'MWK (MK)' },
    { code: 'MXN', name: 'Mexican Peso', symbol: '$', displayName: 'MXN ($)' },
    { code: 'MYR', name: 'Malaysian Ringgit', symbol: 'RM', displayName: 'MYR (RM)' },
    { code: 'MZN', name: 'Metical', symbol: 'MTn', displayName: 'MZN (MTn)' },
    { code: 'NAD', name: 'Namibia Dollar', symbol: '$', displayName: 'NAD ($)' },
    { code: 'NGN', name: 'Naira', symbol: '₦', displayName: 'NGN (₦)' },
    { code: 'NIO', name: 'Cordoba Oro', symbol: 'C$', displayName: 'NIO (C$)' },
    { code: 'NOK', name: 'Norwegian Krone', symbol: 'kr', displayName: 'NOK (kr)' },
    { code: 'NPR', name: 'Nepalese Rupee', symbol: '₨', displayName: 'NPR (₨)' },
    { code: 'NZD', name: 'New Zealand Dollar', symbol: '$', displayName: 'NZD ($)' },
    { code: 'OMR', name: 'Rial Omani', symbol: 'ر.ع.', displayName: 'OMR (ر.ع.)' },
    { code: 'PAB', name: 'Balboa', symbol: 'B/.', displayName: 'PAB (B/.)' },
    { code: 'PEN', name: 'Nuevo Sol', symbol: 'S/.', displayName: 'PEN (S/.)' },
    { code: 'PGK', name: 'Kina', symbol: 'K', displayName: 'PGK (K)' },
    { code: 'PKR', name: 'Pakistan Rupee', symbol: '₨', displayName: 'PKR (₨)' },
    { code: 'PLN', name: 'PZloty', symbol: 'zł', displayName: 'PLN (zł)' },
    { code: 'QAR', name: 'Qatari Rial', symbol: 'ر.ق', displayName: 'QAR (ر.ق)' },
    { code: 'RON', name: 'Leu', symbol: 'L', displayName: 'RON (L)' },
    { code: 'RSD', name: 'Serbian Dinar', symbol: 'din', displayName: 'RSD (din)' },
    { code: 'RUB', name: 'Russian Ruble', symbol: 'р.', displayName: 'RUB (р.)' },
    { code: 'RWF', name: 'Rwanda Franc', symbol: '₣', displayName: 'RWF (₣)' },
    { code: 'SAR', name: 'Saudi Riyal', symbol: 'ر.س', displayName: 'SAR (ر.س)' },
    { code: 'SBD', name: 'Solomon Islands Dollar', symbol: '$', displayName: 'SBD ($)' },
    { code: 'SCR', name: 'Seychelles Rupee', symbol: '₨', displayName: 'SCR (₨)' },
    { code: 'SDG', name: 'Sudanese Pound', symbol: '£', displayName: 'SDG (£)' },
    { code: 'SEK', name: 'Swedish Krona', symbol: 'kr', displayName: 'SEK (kr)' },
    { code: 'SGD', name: 'Singapore Dollar', symbol: '$', displayName: 'SGD ($)' },
    { code: 'SHP', name: 'Saint Helena Pound', symbol: '£', displayName: 'SHP (£)' },
    { code: 'SLL', name: 'Leone', symbol: 'Le', displayName: 'SLL (Le)' },
    { code: 'SOS', name: 'Somali Shilling', symbol: 'Sh', displayName: 'SOS (Sh)' },
    { code: 'SRD', name: 'Suriname Dollar', symbol: '$', displayName: 'SRD ($)' },
    { code: 'STN', name: 'Dobra', symbol: 'Db', displayName: 'STN (Db)' },
    { code: 'SYP', name: 'Syrian Pound', symbol: 'ل.س', displayName: 'SYP (ل.س)' },
    { code: 'SZL', name: 'Lilangeni', symbol: 'L', displayName: 'SZL (L)' },
    { code: 'THB', name: 'Baht', symbol: '฿', displayName: 'THB (฿)' },
    { code: 'TJS', name: 'Somoni', symbol: 'ЅМ', displayName: 'TJS (ЅМ)' },
    { code: 'TMT', name: 'Manat', symbol: 'm', displayName: 'TMT (m)' },
    { code: 'TND', name: 'Tunisian Dinar', symbol: 'د.ت', displayName: 'TND (د.ت)' },
    { code: 'TOP', name: 'Pa’anga', symbol: 'T$', displayName: 'TOP (T$)' },
    { code: 'TRY', name: 'Turkish Lira', symbol: '₤', displayName: 'TRY (₤)' },
    { code: 'TTD', name: 'Trinidad and Tobago Dollar', symbol: '$', displayName: 'TTD ($)' },
    { code: 'TWD', name: 'Taiwan Dollar', symbol: '$', displayName: 'TWD ($)' },
    { code: 'TZS', name: 'Tanzanian Shilling', symbol: 'Sh', displayName: 'TZS (Sh)' },
    { code: 'UGX', name: 'Uganda Shilling', symbol: 'Sh', displayName: 'UGX (Sh)' },
    { code: 'USD', name: 'US Dollar', symbol: '$', displayName: 'USD ($)' },
    { code: 'UYU', name: 'Peso Uruguayo', symbol: '$', displayName: 'UYU ($)' },
    { code: 'VEF', name: 'Bolivar Fuerte', symbol: 'Bs F', displayName: 'VEF (Bs F)' },
    { code: 'VND', name: 'Dong', symbol: '₫', displayName: 'VND (₫)' },
    { code: 'VUV', name: 'Vatu', symbol: 'Vt', displayName: 'VUV (Vt)' },
    { code: 'WST', name: 'Tala', symbol: 'T', displayName: 'WST (T)' },
    { code: 'XAF', name: 'CFA Franc BCEAO', symbol: '₣', displayName: 'XAF (₣)' },
    { code: 'XCD', name: 'East Caribbean Dollar', symbol: '$', displayName: 'XCD ($)' },
    { code: 'XPF', name: 'CFP Franc', symbol: '₣', displayName: 'XPF (₣)' },
    { code: 'ZAR', name: 'Rand', symbol: 'R', displayName: 'ZAR (R)' },
    { code: 'ZMW', name: 'Zambian Kwacha', symbol: 'ZK', displayName: 'ZMW (ZK)' },
    { code: 'ZWL', name: 'Zimbabwe Dollar', symbol: '$', displayName: 'ZWL ($)' }
  ]
}
