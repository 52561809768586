<template>
  <v-container fluid>
    <v-row
      no-gutters
      justify="start"
      class="mb-5"
    >
      <v-toolbar
        elevation="1"
        style="border-left-width:thick; border-left-style:solid; border-left-color:coral;"
      >
        <v-icon :class="`m${rtl ? 'l': 'r'}-3`">
          mdi-account-circle-outline
        </v-icon>
        <v-toolbar-title>{{ $t("Users") }}</v-toolbar-title>
        <v-spacer />
        <new-user-button />
      </v-toolbar>
    </v-row>
    <v-row
      no-gutters
      justify="start"
      class="mb-5"
    >
      <user-search @input="searchList($event)" />
    </v-row>
    <user-list :search="search" />
  </v-container>
</template>

<script>
import UserList from '@/components/users/UsersList.vue'
import NewUserButton from '@/components/users/UserButtonNew.vue'
import UserSearch from '@/components/users/UserInputSearch.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'UserManagement',
  components: {
    UserList,
    UserSearch,
    NewUserButton
  },
  data () {
    return {
      search: ''
    }
  },
  computed: {
    ...mapGetters(['rtl'])
  },
  methods: {
    searchList (data) {
      this.search = data
    }
  }
}
</script>
