<template>
  <v-data-table
    v-if="TenantUsers"
    :headers="headers"
    :items="TenantUsers"
    :search="search"
    :loading="loading"
    :loading-text="$t('Loading, Please wait...')"
    sort-by="username"
    class="elevation-1"
  >
    <template v-slot:[`item.enabled`]="{ item }">
      <v-icon small>
        {{ verifiedIcon(item.enabled) }}
      </v-icon>
    </template>
    <template v-slot:[`item.creation_date`]="{ item }">
      {{ formatDate(item.creation_date) }}
    </template>
    <template v-slot:[`item.email_verified`]="{ item }">
      <v-icon small>
        {{ verifiedIcon(item.email_verified) }}
      </v-icon>
    </template>
    <template v-slot:[`item.phone_number_verified`]="{ item }">
      <v-icon small>
        {{ verifiedIcon(item.phone_number_verified) }}
      </v-icon>
    </template>
    <template v-slot:[`item.related_groups`]="{ item }">
      {{ item.related_groups.map(el => el.name).join(", ") }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        @click="editUser(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteUser(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatDate } from '../../common/formatters'
export default {
  props: {
    search: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: true,
      userFields: [
        { value: 'username', text: 'Username' },
        { value: 'name', text: 'Name' },
        { value: 'email', text: 'Email' },
        { value: 'phone_number', text: 'Phone Number' },
        { value: 'enabled', text: 'Enabled' },
        { value: 'status', text: 'Status' },
        { value: 'related_groups', text: 'Related Groups' },
        { value: 'creation_date', text: 'Creation Date' },
        { value: 'actions', text: 'Actions', sortable: false }
      ]
    }
  },
  computed: {
    ...mapGetters(['users', 'currentTenantId']),
    TenantUsers () {
      return this.users[this.currentTenantId]
    },

    headers () {
      const headers = this.userFields.map(el => {
        return {
          ...el,
          text: this.$t(`${el.text}`),
          class: 'white--text primary'
        }
      })
      return headers
    },
    verifiedIcon () {
      return flag => {
        if (flag === true || flag === 'true') {
          return 'mdi-check-circle-outline'
        }
        if (flag === false || flag === 'false') {
          return 'mdi-close-circle-outline'
        }
        return null
      }
    }
  },
  async beforeCreate () {
    await this.fetchUsers()
  },
  methods: {
    async fetchUsers () {
      this.loading = true
      this.$store.dispatch('fetchUsers')
        .then(() => {
          this.loading = false
        })
    },
    editUser (item) {
      this.$router.push(`/users/${item.username}/edit`)
    },
    deleteUser (item) {
      confirm(`Are you sure you want to delete @${item.username}?`) &&
        this.$store.dispatch('deleteUser', item).then(() => {
          this.fetchUsers()
        })
    },
    formatDate: formatDate
  }
}
</script>
